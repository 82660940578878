<template>
  <div class="content">
    <a-page-header style="padding: 0" title="Pojects Issues" />

    <span style="margin-right: 10px">
      Current Project
      <a-dropdown :trigger="['click']">
        <template #overlay>
          <a-menu>
            <a-menu-item
              v-for="item in pojectList"
              :key="item.id"
              @click="current = item"
            >
              {{ item.name }}({{ item.id }})
            </a-menu-item>
          </a-menu>
        </template>
        <a-button style="min-width: 100px">
          {{ current.name }}
          <DownOutlined />
        </a-button>
      </a-dropdown>
    </span>

    <Datepicker @update="dateUpdate" style="display: inline-flex" />

    <a-radio-group
      v-model:value="value1"
      button-style="solid"
      style="margin-left: 20px"
    >
      <a-radio-button style="height: 34px" value="1">
        <TableOutlined /> Table</a-radio-button
      >
      <a-radio-button style="height: 34px" value="2"
        ><BarChartOutlined /> Chart</a-radio-button
      >
    </a-radio-group>

    <div class="mt-30" v-if="list">
      <IssuesTable :data="list" :loading="listloading" v-if="value1 == 1" />
      <IssuesChart :data="list" v-if="value1 == 2" />
    </div>
  </div>
</template>

<script>
import { useQuery, useResult } from "@vue/apollo-composable";

import {
  DownOutlined,
  BarChartOutlined,
  TableOutlined,
} from "@ant-design/icons-vue";
import { reactive, toRefs, ref, inject } from "vue";
import IssuesTable from "@/components/table/IssuesTable";
import IssuesChart from "@/components/chart/IssuesChart";
import Datepicker from "@/components/DatePick";
export default {
  components: {
    IssuesChart,
    IssuesTable,
    DownOutlined,
    Datepicker,
    TableOutlined,
    BarChartOutlined,
  },

  setup() {
    const { $msg, gql } = inject("$");

    const v = reactive({
      value1: "1",
      dimension: "year",
      start: "Y2019",
      // end: "Y2023",
      end: "Y"+ new Date().getFullYear()
    });

    const QL = gql`
      query {
        projects {
          id
          name
        }
      }
    `;

    const {
      result: projectRes,
      onResult: onProjectRes,
      loading: pojectListloading,
      onError: onProjectErr,
    } = useQuery(QL, null, { fetchPolicy: "network-only" });

    const pojectList = useResult(projectRes, null, (data) => data.projects);

    const current = ref({});

    const enabled = ref(false);

    onProjectRes((res) => {
      if (!res.data) return;
      current.value = res.data.projects[0] ? res.data.projects[0] : {};
      if (res.data.projects[0]) {
        enabled.value = true;
      } else {
        $msg.warning("Have no project");
      }
    });
    onProjectErr((error) => {
      $msg.error("Failed to fetch projects");
    });

    const QUERY_ISSUES = gql`
      query ($projectId: Int, $timeDimensions: String, $time: [String]) {
        issuesByProjectId(
          projectId: $projectId
          timeDimensions: $timeDimensions
          time: $time
        ) {
          onTimeCount
          advanceCount
          advanceTime
          overdueCount
          overdueTime
          postponeCount
          postponeNum
          postponeTime
          timeDimensions
          time
        }
      }
    `;

    const {
      result,
      onResult,
      loading: listloading,
      onError,
      refetch,
    } = useQuery(
      QUERY_ISSUES,
      () => ({
        projectId: current.value.id || 162,
        timeDimensions: v.dimension,
        time: [v.start, v.end],
      }),
      { fetchPolicy: "network-only" }
    );
    const list = useResult(result, [], (data) => data.issuesByProjectId);

    onResult((queryResult) => {
      if (!queryResult.data) return;
    });
    onError((error) => {
      $msg.error("Failed to fetch data");
    });

    function dateUpdate(params) {
      console.log(params);
      let { dimension, start, end } = params;
      v.dimension = dimension;
      v.start = start;
      v.end = end;
    }

    return {
      ...toRefs(v),
      list,
      listloading,
      pojectList,
      pojectListloading,
      current,
      dateUpdate,
      enabled,
    };
  },
};
</script>
<style lang="scss" scoped></style>
